@import 'base';

.capeb-sommaire {
  
  &__header {
    background: $color__dark;
    color: $color__light;
    padding: 1.25rem 1rem;
    text-align: left;
  }
  
  &__logo {
    max-width: 100%;
    height: auto;
    width: 200px;
  }
  
  &__maintitle {
    font-size: 2rem;
    margin: 0px;
    padding: 0px;
  }
  
  &__title {
    text-align: center;
    padding: 1rem;
    font-size: 2rem;
    margin: 1.5rem 0px;
    color: $color__primary;
  }
  
  &__button {
    display: block;
    text-align: center;
    font-family: $font__title;
    font-size: 1rem;
    text-transform: uppercase;
    border: 2px solid $color__dark;
    background: transparent;
    color: $color__dark;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    margin: 0.5rem auto;
    
    &:hover {
      box-shadow: 0px 0px 5px rgba(black, 0.25);
      border-color: $color__primary;
      color: $color__primary;
    }
    
    &--active,
    &--active:hover {
      color: $color__light;
      background: $color__primary;
      border-color: $color__primary;
    }
  }
  
  &__sub {
    background: rgba($color__grey, 0.5);
    border-radius: 0.5rem;
    overflow: hidden;
  }
  
  &__subtitle {
    margin: 0px 0px 0.5rem;
    padding: 1rem;
    text-transform: uppercase;
    background: $color__grey_alt;
    font-size: 1rem;
    text-align: center;
    color: $color__light;
  }
  
  &__graphs {
    list-style-type: none;
    padding: 0.5rem 1rem;
  }
  
  &__graph {
    padding: 0.375rem 0.25rem;
    border-bottom: 1px solid $color__grey;
    
    &:last-child {
      border-bottom: none;
    }
    
    &:hover {
      color: $color__primary;
    }
    
    a {
      color: inherit;
      text-decoration: none;
      cursor: pointer;
    }
  }
}