@import 'base';

.capeb-pdf {
  
  &__header {
    background: #ddd;
    padding: 2rem 1rem;
    margin-bottom: 1rem;
  }
  
  &__logo {
    background: url('../../../img/logo-capeb.png') no-repeat center;
    background-size: contain;
    width: 70%;
    &::after {
      content: '';
      display: block;
      padding-bottom: 46%;
    }
  }
  
  &__htitle {
    font-size: 2rem;
    color: $color__dark;
    margin: 0px 0px 1.5rem;
    font-weight: bold;
  }
  
  &__hsubtitle {
    font-size: 1.5rem;
    color: $color__primary;
    margin: 0px 0px 1.5rem;
    font-weight: bold;
  }
  
  &__hdate {
    font-size: 1.125rem;
    color: $color__primary;
  }
  
  
  &__gtitle {
    padding: 1rem;
    background: $color__primary;
    color: $color__light;
    margin: 1rem 0px;
    font-size: 2rem;
    font-weight: normal;
  }
  
  &__gsubtitle {
    text-align: center;
    padding: 1rem;
    border: 2px solid $color__primary;
    border-radius: 0.25rem;
    color: $color__primary;
    margin: 1rem 0px;
    font-size: 1.5rem;
    font-weight: normal;
  }
}