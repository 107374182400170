

@font-face {
  font-family: 'Interstate';
  src: url('/fonts/Interstate.eot');
  src: url('/fonts/Interstate.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Interstate.woff2') format('woff2'),
      url('/fonts/Interstate.woff') format('woff'),
      url('/fonts/Interstate.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Interstate Condensed';
  src: url('/fonts/InterstateLightCondensed.eot');
  src: url('/fonts/InterstateLightCondensed.eot?#iefix') format('embedded-opentype'),
      url('/fonts/InterstateLightCondensed.woff2') format('woff2'),
      url('/fonts/InterstateLightCondensed.woff') format('woff'),
      url('/fonts/InterstateLightCondensed.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Interstate Condensed';
  src: url('/fonts/InterstateRegularCondensed.eot');
  src: url('/fonts/InterstateRegularCondensed.eot?#iefix') format('embedded-opentype'),
      url('/fonts/InterstateRegularCondensed.woff2') format('woff2'),
      url('/fonts/InterstateRegularCondensed.woff') format('woff'),
      url('/fonts/InterstateRegularCondensed.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Interstate';
  src: url('/fonts/Interstate-Light.eot');
  src: url('/fonts/Interstate-Light.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Interstate-Light.woff2') format('woff2'),
      url('/fonts/Interstate-Light.woff') format('woff'),
      url('/fonts/Interstate-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Interstate Condensed';
  src: url('/fonts/InterstateBoldCondensed.eot');
  src: url('/fonts/InterstateBoldCondensed.eot?#iefix') format('embedded-opentype'),
      url('/fonts/InterstateBoldCondensed.woff2') format('woff2'),
      url('/fonts/InterstateBoldCondensed.woff') format('woff'),
      url('/fonts/InterstateBoldCondensed.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

