@import '~bootstrap/scss/bootstrap';

@import 'fonts';

html {
  position: relative;
}

.mapael .map {
  position: relative;
}

.mapael .mapTooltip {
  position: absolute;
  background-color: #474c4b;
  opacity: 0.70;
  border-radius: 10px;
  padding: 10px;
  z-index: 1000;
  max-width: 200px;
  display: none;
  color: #fff;
}