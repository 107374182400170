@import '../../pages/capeb/variables';

.capeb-page-title {
  padding: 1.5rem 0px;
  text-align: center;
  background: $color__dark;
  color: $color__light;
  
  &__text {
    padding: 0px;
    margin: 0px;
    font-size: 1.75rem;
  }
}