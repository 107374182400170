
// -------------------------------
// COLORS
// -------------------------------
$color__primary: #c6362d;
$color__light: #ffffff;
$color__dark: #222220;
$color__grey: #b0b0b0;
$color__grey_alt: #636362;

$color__purple: #54287f;
$color__yellow: #dc9124;
$color__green: #5ea895;
$color__blue: #5899d4;


$font__base: 'Interstate';
$font__title: 'Interstate Condensed';