@import '../../pages/capeb/variables';

.capeb-filters {
  padding: 0.5rem 0px;
  background: $color__grey;
  // color: $color__light;
  margin-bottom: 0.5rem;
  
  .form-label {
    text-transform: uppercase;
    font-weight: bold;
    font-family: $font__title;
    text-transform: uppercase;
    color: $color__primary;
  }
}