@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(black, 0.3);
  
  &__inner {
    text-align: center;
  }
  
  &__spinner {
    display: block;
    width: 7rem;
    height: 7rem;
    border: 0.5rem solid #000;
    border-bottom-color: transparent;
    border-radius: 50%;
    animation: rotation 1s linear infinite;
    margin: auto;
  }
  
  &__text {
    margin: 1.5rem auto;
    font-size: 1.5rem;
    line-height: 1;
  }
  
  &--fixed {
    position: fixed;
  }
}